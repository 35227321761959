<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Prealerts</h3>

            </div>
            <div class="card-toolbar row">
              <div class="col-8"></div>
              <div class="col-3">
                <b-form-input class="float-right" v-model="filtershipments" type="search"
                  placeholder="Type to search"></b-form-input>
              </div>
              <div class="col-1">
                <b-dropdown class="mr-2 float-right" right no-carret>
                  <template #button-content>
                    <!-- <i class="flaticon-list-3"></i> -->
                    <span>Actions</span>
                  </template>
                  <b-dropdown-item @click="showUpdateStatusModal()"><span class="text-dark"><i
                        class="flaticon2-tag text-dark"></i>&nbsp;Update Status</span></b-dropdown-item>
                  <b-dropdown-item @click="$bvModal.show('assign-to-container-modal')"><span class="text-dark"><i
                        class="flaticon2-box text-dark"></i>&nbsp;Assign Shipments</span></b-dropdown-item>
                  <b-dropdown-item @click="setMarkasPaid()"><span class="text-dark"><i
                        class="flaticon2-check-mark text-dark"></i>&nbsp;Mark as paid</span></b-dropdown-item>
                  <b-dropdown-item @click="sendInvoices()"><span class="text-dark"><i
                        class="flaticon2-mail text-dark"></i>&nbsp;Send Invoice</span></b-dropdown-item>
                  <b-dropdown-item @click="deleteShipments()"><span class="text-dark"><i
                        class="flaticon2-delete text-dark"></i>&nbsp;Delete Shipments</span></b-dropdown-item>
                </b-dropdown>
              </div>

            </div>
          </div>
          <div class="card-body body-fluid">
            <b-tabs content-class="mt-3">
              <b-tab title="All" active>
                <div class="table-responsive">
                  <b-table id="shipments-tbl" striped hover :items="shipments" :fields="fields" :filter="filtershipments"
                    :per-page="perPage" :current-page="currentPage" :select-mode="multi" ref="selectableTable-0"
                    selectable no-select-on-click class="text-nowrap" @filtered="onFiltered"
                    @row-selected="onRowSelected">
                    <template #cell(unique_id)="row">
                      <a class="a" @click="GetRecordC(row.item.cid)">
                        {{ row.item.unique_id }}
                      </a>
                    </template>
                    <template #head(select)="data">
                      <input type="checkbox" v-model="checkAll" @click="selectAllcheckboxes('selectableTable-0')">
                    </template>
                    <template #cell(select)="{ rowSelected, index }">
                      <input type="checkbox" v-model="rowSelected" @click="checkRow('selectableTable-0', index)">
                    </template>
                    <template #cell(in_container)="row">
                      <span class="badge badge-pill badge-success">{{
                        containerStatus(row.item.in_container)
                      }}</span>
                    </template>
                    <template #cell(payment_status)="row">
                      <template v-if="row.item.payment_status == 1">
                        <span class="badge badge-pill badge-info">Paid</span>
                      </template>
                      <template v-else>
                        <span class="badge badge-pill badge-warning">Due</span>
                      </template>
                    </template>
                    <template #cell(tracking_no)="row">
                      <a class="a" @click="GetRecord(row.item)">{{
                        row.item.tracking_no
                      }}</a>
                    </template>
                  </b-table>
                  <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right"
                    aria-controls="shipments-tbl"></b-pagination>
                </div>
              </b-tab>
              <b-tab title="Received">
                <div class="table-responsive">
                  <b-table id="shipments-tbl" striped hover :items="receivedshipments" :fields="fields"
                    :filter="filtershipments" :per-page="perPage" :current-page="currentPage" :select-mode="multi"
                    ref="selectableTable-1" selectable no-select-on-click class="text-nowrap" @filtered="onFiltered"
                    @row-selected="onRowSelected">
                    <template #cell(unique_id)="row">
                      <a class="a" @click="GetRecordC(row.item.cid)">
                        {{ row.item.unique_id }}
                      </a>
                    </template>
                    <template #head(select)="data">
                      <input type="checkbox" v-model="checkAll" @click="selectAllcheckboxes('selectableTable-1')">
                    </template>
                    <template #cell(select)="{ rowSelected, index }">
                      <input type="checkbox" v-model="rowSelected" @click="checkRow('selectableTable-1', index)">
                    </template>
                    <template #cell(in_container)="row">
                      <span class="badge badge-pill badge-success">{{
                        containerStatus(row.item.in_container)
                      }}</span>
                    </template>
                    <template #cell(payment_status)="row">
                      <template v-if="row.item.payment_status == 1">
                        <span class="badge badge-pill badge-info">Paid</span>
                      </template>
                      <template v-else>
                        <span class="badge badge-pill badge-warning">Due</span>
                      </template>
                    </template>
                    <template #cell(tracking_no)="row">
                      <a class="a" @click="GetRecord(row.item)">{{
                        row.item.tracking_no
                      }}</a>
                    </template>
                  </b-table>
                  <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right"
                    aria-controls="shipments-tbl"></b-pagination>
                </div>
              </b-tab>
              <b-tab title="Picked">
                <div class="table-responsive">
                  <b-table id="shipments-tbl" striped hover :items="pickedshipments" :fields="fields"
                    :filter="filtershipments" :per-page="perPage" :current-page="currentPage" :select-mode="multi"
                    ref="selectableTable-2" selectable no-select-on-click class="text-nowrap" @filtered="onFiltered"
                    @row-selected="onRowSelected">
                    <template #cell(unique_id)="row">
                      <a class="a" @click="GetRecordC(row.item.cid)">
                        {{ row.item.unique_id }}
                      </a>
                    </template>
                    <template #head(select)="data">
                      <input type="checkbox" v-model="checkAll" @click="selectAllcheckboxes('selectableTable-2')">
                    </template>
                    <template #cell(select)="{ rowSelected, index }">
                      <input type="checkbox" v-model="rowSelected" @click="checkRow('selectableTable-2', index)">
                    </template>
                    <template #cell(in_container)="row">
                      <span class="badge badge-pill badge-success">{{
                        containerStatus(row.item.in_container)
                      }}</span>
                    </template>
                    <template #cell(payment_status)="row">
                      <template v-if="row.item.payment_status == 1">
                        <span class="badge badge-pill badge-info">Paid</span>
                      </template>
                      <template v-else>
                        <span class="badge badge-pill badge-warning">Due</span>
                      </template>
                    </template>
                    <template #cell(tracking_no)="row">
                      <a class="a" @click="GetRecord(row.item)">{{
                        row.item.tracking_no
                      }}</a>
                    </template>
                  </b-table>
                  <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right"
                    aria-controls="shipments-tbl"></b-pagination>
                </div>
              </b-tab>
              <b-tab title="Origin Facility">
                <div class="table-responsive">
                  <b-table id="shipments-tbl" striped hover :items="originfacilityshipments" :fields="fields"
                    :filter="filtershipments" :per-page="perPage" :current-page="currentPage" :select-mode="multi"
                    ref="selectableTable-3" selectable no-select-on-click class="text-nowrap" @filtered="onFiltered"
                    @row-selected="onRowSelected">
                    <template #cell(unique_id)="row">
                      <a class="a" @click="GetRecordC(row.item.cid)">
                        {{ row.item.unique_id }}
                      </a>
                    </template>
                    <template #head(select)="data">
                      <input type="checkbox" v-model="checkAll" @click="selectAllcheckboxes('selectableTable-3')">
                    </template>
                    <template #cell(select)="{ rowSelected, index }">
                      <input type="checkbox" v-model="rowSelected" @click="checkRow('selectableTable-3', index)">
                    </template>
                    <template #cell(in_container)="row">
                      <span class="badge badge-pill badge-success">{{
                        containerStatus(row.item.in_container)
                      }}</span>
                    </template>
                    <template #cell(payment_status)="row">
                      <template v-if="row.item.payment_status == 1">
                        <span class="badge badge-pill badge-info">Paid</span>
                      </template>
                      <template v-else>
                        <span class="badge badge-pill badge-warning">Due</span>
                      </template>
                    </template>
                    <template #cell(tracking_no)="row">
                      <a class="a" @click="GetRecord(row.item)">{{
                        row.item.tracking_no
                      }}</a>
                    </template>
                  </b-table>
                  <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right"
                    aria-controls="shipments-tbl"></b-pagination>
                </div>
              </b-tab>
              <b-tab title="Departure Facility">
                <div class="table-responsive">
                  <b-table id="shipments-tbl" striped hover :items="departurefacilityshipments" :fields="fields"
                    :filter="filtershipments" :per-page="perPage" :current-page="currentPage" :select-mode="multi"
                    ref="selectableTable-4" selectable no-select-on-click class="text-nowrap" @filtered="onFiltered"
                    @row-selected="onRowSelected">
                    <template #cell(unique_id)="row">
                      <a class="a" @click="GetRecordC(row.item.cid)">
                        {{ row.item.unique_id }}
                      </a>
                    </template>
                    <template #head(select)="data">
                      <input type="checkbox" v-model="checkAll" @click="selectAllcheckboxes('selectableTable-4')">
                    </template>
                    <template #cell(select)="{ rowSelected, index }">
                      <input type="checkbox" v-model="rowSelected" @click="checkRow('selectableTable-4', index)">
                    </template>
                    <template #cell(in_container)="row">
                      <span class="badge badge-pill badge-success">{{
                        containerStatus(row.item.in_container)
                      }}</span>
                    </template>
                    <template #cell(payment_status)="row">
                      <template v-if="row.item.payment_status == 1">
                        <span class="badge badge-pill badge-info">Paid</span>
                      </template>
                      <template v-else>
                        <span class="badge badge-pill badge-warning">Due</span>
                      </template>
                    </template>
                    <template #cell(tracking_no)="row">
                      <a class="a" @click="GetRecord(row.item)">{{
                        row.item.tracking_no
                      }}</a>
                    </template>
                  </b-table>
                  <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right"
                    aria-controls="shipments-tbl"></b-pagination>
                </div>
              </b-tab>
              <b-tab title="InTransit">
                <div class="table-responsive">
                  <b-table id="shipments-tbl" striped hover :items="intransitshipments" :fields="fields"
                    :filter="filtershipments" :per-page="perPage" :current-page="currentPage" :select-mode="multi"
                    ref="selectableTable-5" selectable no-select-on-click class="text-nowrap" @filtered="onFiltered"
                    @row-selected="onRowSelected">
                    <template #cell(unique_id)="row">
                      <a class="a" @click="GetRecordC(row.item.cid)">
                        {{ row.item.unique_id }}
                      </a>
                    </template>
                    <template #head(select)="data">
                      <input type="checkbox" v-model="checkAll" @click="selectAllcheckboxes('selectableTable-5')">
                    </template>
                    <template #cell(select)="{ rowSelected, index }">
                      <input type="checkbox" v-model="rowSelected" @click="checkRow('selectableTable-5', index)">
                    </template>
                    <template #cell(in_container)="row">
                      <span class="badge badge-pill badge-success">{{
                        containerStatus(row.item.in_container)
                      }}</span>
                    </template>
                    <template #cell(payment_status)="row">
                      <template v-if="row.item.payment_status == 1">
                        <span class="badge badge-pill badge-info">Paid</span>
                      </template>
                      <template v-else>
                        <span class="badge badge-pill badge-warning">Due</span>
                      </template>
                    </template>
                    <template #cell(tracking_no)="row">
                      <a class="a" @click="GetRecord(row.item)">{{
                        row.item.tracking_no
                      }}</a>
                    </template>
                  </b-table>
                  <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right"
                    aria-controls="shipments-tbl"></b-pagination>
                </div>
              </b-tab>
              <b-tab title="Custom">
                <div class="table-responsive">
                  <b-table id="shipments-tbl" striped hover :items="customshipments" :fields="fields"
                    :filter="filtershipments" :per-page="perPage" :current-page="currentPage" :select-mode="multi"
                    ref="selectableTable-6" selectable no-select-on-click class="text-nowrap" @filtered="onFiltered"
                    @row-selected="onRowSelected">
                    <template #cell(unique_id)="row">
                      <a class="a" @click="GetRecord(row.item.cid)">
                        {{ row.item.unique_id }}
                      </a>
                    </template>
                    <template #head(select)="data">
                      <input type="checkbox" v-model="checkAll" @click="selectAllcheckboxes('selectableTable-6')">
                    </template>
                    <template #cell(select)="{ rowSelected, index }">
                      <input type="checkbox" v-model="rowSelected" @click="checkRow('selectableTable-6', index)">
                    </template>
                    <template #cell(in_container)="row">
                      <span class="badge badge-pill badge-success">{{
                        containerStatus(row.item.in_container)
                      }}</span>
                    </template>
                    <template #cell(payment_status)="row">
                      <template v-if="row.item.payment_status == 1">
                        <span class="badge badge-pill badge-info">Paid</span>
                      </template>
                      <template v-else>
                        <span class="badge badge-pill badge-warning">Due</span>
                      </template>
                    </template>
                    <template #cell(tracking_no)="row">
                      <a class="a" @click="GetRecord(row.item)">{{
                        row.item.tracking_no
                      }}</a>
                    </template>
                  </b-table>
                  <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right"
                    aria-controls="shipments-tbl"></b-pagination>
                </div>
              </b-tab>
              <b-tab title="Destination">
                <div class="table-responsive">
                  <b-table id="shipments-tbl" striped hover :items="destinationshipments" :fields="fields"
                    :filter="filtershipments" :per-page="perPage" :current-page="currentPage" :select-mode="multi"
                    ref="selectableTable-7" selectable no-select-on-click class="text-nowrap" @filtered="onFiltered"
                    @row-selected="onRowSelected">
                    <template #cell(unique_id)="row">
                      <a class="a" @click="GetRecordC(row.item.cid)">
                        {{ row.item.unique_id }}
                      </a>
                    </template>
                    <template #head(select)="data">
                      <input type="checkbox" v-model="checkAll" @click="selectAllcheckboxes('selectableTable-7')">
                    </template>
                    <template #cell(select)="{ rowSelected, index }">
                      <input type="checkbox" v-model="rowSelected" @click="checkRow('selectableTable-7', index)">
                    </template>
                    <template #cell(in_container)="row">
                      <span class="badge badge-pill badge-success">{{
                        containerStatus(row.item.in_container)
                      }}</span>
                    </template>
                    <template #cell(payment_status)="row">
                      <template v-if="row.item.payment_status == 1">
                        <span class="badge badge-pill badge-info">Paid</span>
                      </template>
                      <template v-else>
                        <span class="badge badge-pill badge-warning">Due</span>
                      </template>
                    </template>
                    <template #cell(tracking_no)="row">
                      <a class="a" @click="GetRecord(row.item)">{{
                        row.item.tracking_no
                      }}</a>
                    </template>
                  </b-table>
                  <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right"
                    aria-controls="shipments-tbl"></b-pagination>
                </div>
              </b-tab>
              <b-tab title="In Delivery">
                <div class="table-responsive">
                  <b-table id="shipments-tbl" striped hover :items="indeliveryshipments" :fields="fields"
                    :filter="filtershipments" :per-page="perPage" :current-page="currentPage" :select-mode="multi"
                    ref="selectableTable-8" selectable no-select-on-click class="text-nowrap" @filtered="onFiltered"
                    @row-selected="onRowSelected">
                    <template #cell(unique_id)="row">
                      <a class="a" @click="GetRecordC(row.item.cid)">
                        {{ row.item.unique_id }}
                      </a>
                    </template>
                    <template #head(select)="data">
                      <input type="checkbox" v-model="checkAll" @click="selectAllcheckboxes('selectableTable-8')">
                    </template>
                    <template #cell(select)="{ rowSelected, index }">
                      <input type="checkbox" v-model="rowSelected" @click="checkRow('selectableTable-8', index)">
                    </template>
                    <template #cell(in_container)="row">
                      <span class="badge badge-pill badge-success">{{
                        containerStatus(row.item.in_container)
                      }}</span>
                    </template>
                    <template #cell(payment_status)="row">
                      <template v-if="row.item.payment_status == 1">
                        <span class="badge badge-pill badge-info">Paid</span>
                      </template>
                      <template v-else>
                        <span class="badge badge-pill badge-warning">Due</span>
                      </template>
                    </template>
                    <template #cell(tracking_no)="row">
                      <a class="a" @click="GetRecord(row.item)">{{
                        row.item.tracking_no
                      }}</a>
                    </template>
                  </b-table>
                  <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right"
                    aria-controls="shipments-tbl"></b-pagination>
                </div>
              </b-tab>
              <b-tab title="Delivered">
                <div class="table-responsive">
                  <b-table id="shipments-tbl" striped hover :items="deliveredshipments" :fields="fields"
                    :filter="filtershipments" :per-page="perPage" :current-page="currentPage" :select-mode="multi"
                    ref="selectableTable-9" selectable no-select-on-click class="text-nowrap" @filtered="onFiltered"
                    @row-selected="onRowSelected">
                    <template #cell(unique_id)="row">
                      <a class="a" @click="GetRecordC(row.item.cid)">
                        {{ row.item.unique_id }}
                      </a>
                    </template>
                    <template #head(select)="data">
                      <input type="checkbox" v-model="checkAll" @click="selectAllcheckboxes('selectableTable-9')">
                    </template>
                    <template #cell(select)="{ rowSelected, index }">
                      <input type="checkbox" v-model="rowSelected" @click="checkRow('selectableTable-9', index)">
                    </template>
                    <template #cell(in_container)="row">
                      <span class="badge badge-pill badge-success">{{
                        containerStatus(row.item.in_container)
                      }}</span>
                    </template>
                    <template #cell(payment_status)="row">
                      <template v-if="row.item.payment_status == 1">
                        <span class="badge badge-pill badge-info">Paid</span>
                      </template>
                      <template v-else>
                        <span class="badge badge-pill badge-warning">Due</span>
                      </template>
                    </template>
                    <template #cell(tracking_no)="row">
                      <a class="a" @click="GetRecord(row.item)">{{
                        row.item.tracking_no
                      }}</a>
                    </template>
                  </b-table>
                  <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right"
                    aria-controls="shipments-tbl"></b-pagination>
                </div>
              </b-tab>
              <!-- <b-tab title="InContainer"></b-tab> -->
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <loading :active.sync="isLoading"></loading>

    <b-modal id="assign-to-container-modal" ref="modal" ok-title="Assign" title="Assign To Container"
      @ok="assignToContainer" size="md">
      <form ref="editform" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-12 mt-2">
            <label class="font-weight-bold">Select Container <span class="text-danger">*</span></label>
            <b-form-select value-field="id" v-model="selectedContainerId" text-field="name" :options="allcontainers">
            </b-form-select>
          </div>
        </div>
      </form>
    </b-modal>


    <b-modal id="update-shipment-status-modal" ref="modal" ok-title="Update" title="Update Shipment Status"
      @ok="updateShipmentStatus" @hidden="resetUpdateStatusModal" size="lg">
      <div class="row">
        <div class="col-md-4">
          <b-form-group label="Date & Time">
            <VueCtkDateTimePicker format="YYYY-MM-DD HH:mm:ss" v-model="statusObj.datetime" />
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Status">
            <b-form-input v-model="statusObj.status" list="statusDataList"></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Location">
            <b-form-input list="locationsDataList" v-model="statusObj.location"></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Remarks">
            <b-form-input list="remarksDataList" v-model="statusObj.remarks"></b-form-input>
          </b-form-group>
        </div>
      </div>
    </b-modal>


    <datalist id="statusDataList">
      <option v-for="(item, index) in statusesDataList" :key="index">
        {{ item.name }}
      </option>
    </datalist>
    <datalist id="locationsDataList">
      <option v-for="(item, index) in locationDataList" :key="index">
        {{ item.name
        }}<span v-if="item.countrycode != null">, {{ item.countrycode }}</span>
      </option>
    </datalist>
    <datalist id="remarksDataList">
      <option v-for="(item, index) in remarksDataList" :key="index">
        {{ item.name }}
      </option>
    </datalist>

  </div>
</template>
  
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      selected: [],
      shipments: [],
      receivedshipments: [],
      pickedshipments: [],
      originfacilityshipments: [],
      departurefacilityshipments: [],
      intransitshipments: [],
      customshipments: [],
      destinationshipments: [],
      indeliveryshipments: [],
      deliveredshipments: [],
      allshipments: [],
      allcontainers: [],
      statusesDataList: [],
      locationDataList: [],
      remarksDataList: [],
      selectedContainerId: 0,
      checkAll: false,
      type: 0,
      rows: 0,
      perPage: 30,
      isLoading: false,
      currentPage: 1,
      filtershipments: null,
      statusObj: {
        id: null,
        shipment_ids: [],
        datetime: "",
        status: "",
        location: "",
        remarks: "",
      },
      typelist: [
        {
          id: 0,
          name: "All",
        },
        {
          id: 1,
          name: "Prealerts",
        },
        {
          id: 2,
          name: "Shipments",
        },
      ],
      fields: [
        {
          key: "select",
        },
        {
          key: "tracking_no",
          label: "Tracking No.",
        },
        {
          key: "unique_id",
          label: "Customer Id",
        },
        {
          key: "courier_tracking_no",
          label: "Reference No.",
        },
        {
          key: "sender_name",
          label: "Sender",
          formatter: (value, key, item) => {

            if (item.type == 1) {
              return item.prealert_sender_name;
            }
            if (item.type == 2) {
              return value;
            }
          },
        },
        {
          key: "receiver_name",
          label: "Receiver",
        },
        {
          key: "receiver_city",
          label: "Origin To Destination",
          formatter: (value, key, item) => {

            if (item.type == 1) {
              return item.prealert_sender_city + " To " + value;
            }
            if (item.type == 2) {
              return item.sender_city + " To " + value;
            }
          },
        },
        {
          key: "branch_name",
          label: "Branch",
        },
        {
          key: "shipment_payer",
          label: "Payer",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "shipping_mode",
          label: "Mode",
        },
        {
          key: "shipment_type",
          label: "Type",
        },
        {
          key: "purchase_date",
          label: "Booking Date",
          formatter: (value, key, item) => {
            return new Date(item.booking_date).toLocaleDateString();
          },
        },
        {
          key: "payment_status",
          label: "Invoice Status",
        },
        {
          key: "in_container",
          label: "In Container",
          formatter: (value) => {
            if (value == 1) {
              return "Yes";
            } else {
              return "";
            }
          },
        },
        {
          key: "created_at",
          label: "Created At",
          formatter: (value) => {
            return new Date(value).toLocaleString();
          },
        },
      ],
      selectedShipments: [],
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  components: {
    Loading,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Shipment",
      },
      {
        title: "All Prealerts",
      },
    ]);
    this.isLoading = true;
    this.getAllShipments();
    this.fetchallcontainers();
    this.getStatusDataList();
    this.getRemarksDataList();
    this.getLocationsDataList();
  },
  methods: {
    GetRecord(row) {
      this.$router.push({
        name: "view-shipment",
        params: {
          id: row.id,
        },
      });
    },
    checkRow(ref, index) {
      if (this.$refs[ref].isRowSelected(index)) {
        this.$refs[ref].unselectRow(index);
      } else {
        this.$refs[ref].selectRow(index);
      }
    },
    selectAllcheckboxes(ref) {
      if (this.checkAll) {
        this.$refs[ref].clearSelected();
      } else {
        this.$refs[ref].selectAllRows();
      }
    },
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
      this.currentPage = 1;
    },
    filter() {
      if (this.type > 0) {
        this.shipments = this.allshipments.filter((x) => x.type == this.type);
      } else {
        this.shipments = this.allshipments;
      }
    },
    getAllShipments() {
      if (this.currentUser.role == 1 || this.currentUser.role == 2) {
        ApiService.get("getallprealert")
          .then(({ data }) => {

            this.shipments = data.filter(x => x.type == 1);
            this.receivedshipments = this.shipments.filter(x => x.status == "Order Received");
            this.pickedshipments = this.shipments.filter(x => x.status == "Picked Up");
            this.originfacilityshipments = this.shipments.filter(x => x.status == "Origin IndoBox Facility");
            this.departurefacilityshipments = this.shipments.filter(x => x.status == "Departed IndoBox Facility");
            this.intransitshipments = this.shipments.filter(x => x.status == "In Transit");
            this.customshipments = this.shipments.filter(x => x.status == "Import Customs Processing");
            this.destinationshipments = this.shipments.filter(x => x.status == "Destination IndoBox Facility");
            this.indeliveryshipments = this.shipments.filter(x => x.status == "In Transit for Delivery");
            this.deliveredshipments = this.shipments.filter(x => x.status == "Delivered");
            // this.incontainershipments = this.shipments.filter(x=> x.status == "Order Received");
            this.allshipments = data.filter(x => x.type == 1);
            this.rows = this.shipments.length;
            this.isLoading = false;
          })
          .catch(({ response }) => {
            this.isLoading = false;
            for (let field of Object.keys(response.data.error)) {
              this.makeToastVariant("danger", response.data.error[field][0]);
            }
          });
      } else {
        ApiService.get("getprealertsbybranch")
          .then(({ data }) => {

            this.shipments = data.filter(x => x.type == 1);
            this.allshipments = data.filter(x => x.type == 1);
            this.rows = this.shipments.length;
            this.isLoading = false;
          })
          .catch(({ response }) => {
            this.isLoading = false;
            for (let field of Object.keys(response.data.error)) {
              this.makeToastVariant("danger", response.data.error[field][0]);
            }
          });
      }
    },
    makeToastVariant(variant = null, text) {
      this.$bvToast.toast(text, {
        title: `Error`,
        variant: variant,
        solid: true,
      });
    },
    onRowSelected(items) {
      this.selected = items;
    },
    fetchallcontainers() {
      ApiService.get("getcontainers")
        .then(({ data }) => {
          this.allcontainers = data.containers;
        })
        .catch(({ response }) => {
          this.isLoading = false;
          for (let field of Object.keys(response.data.error)) {
            this.makeToastVariant("danger", response.data.error[field][0]);
          }
        });
    },
    containerStatus(status) {
      if (status == 1) {
        return "Yes";
      } else {
        return "";
      }
    },
    getStatusDataList() {
      ApiService.get("shipmentstatuslist")
        .then(({ data }) => {
          this.statusesDataList = data;
        })
        .catch(() => { });
    },
    getLocationsDataList() {
      ApiService.get("getlocationsforshipmentstatus")
        .then(({ data }) => {
          this.locationDataList = data.locations;
        })
        .catch(() => { });
    },
    getRemarksDataList() {
      ApiService.get("remarksstatuslist")
        .then(({ data }) => {
          this.remarksDataList = data;
        })
        .catch(() => { });
    },
    assignToContainer() {
      //Check if the shipments are selected.
      if (this.selected.length == 0) {
        this.makeToastVariant(
          "error",
          "No Shipment is Selected to add in container",
          "Error"
        );
        return;
      }
      this.selected.forEach((value, index) => {
        index;
        this.selectedShipments.push({
          containerid: this.selectedContainerId,
          shipmentid: value.id,
        });
      });

      ApiService.post("containershipment", {
        shipment: this.selectedShipments,
      })
        .then(() => {
          this.$nextTick(() => {
            this.makeToastVariant(
              "warning",
              "Shipments added to container successfully",
              "Success"
            );
            this.isLoading = false;
            this.$router.push({
              name: "viewcontainer",
              params: {
                id: this.selectedContainerId,
              },
            });
          });
        })
        .catch(({ response }) => {
          this.isLoading = false;
          for (let field of Object.keys(response.data.error)) {
            this.makeToastVariant(
              "danger",
              response.data.error[field][0],
              "Error"
            );
          }
        });
    },
    showUpdateStatusModal() {
      if (this.selected.length == 0) {
        this.makeToastVariant(
          "error",
          "No Shipment is Selected",
          "Error"
        );
        return;
      }
      this.$bvModal.show("update-shipment-status-modal");
    },
    updateShipmentStatus() {
      var shipmentids = [];
      this.selected.forEach((value) => {
        shipmentids.push(value.id);
      });
      this.statusObj.shipment_ids = shipmentids;
      this.isLoading = true;
      ApiService.post("bulkUpdateShipmentStatus", this.statusObj)
        .then(() => {

          this.isLoading = false;
          this.getAllShipments();
          this.$nextTick(() => {
            this.makeToastVariant(
              "success",
              "Shipments status updated successfully",
              "Success"
            );
          });
        })
        .catch(({ response }) => {
          this.isLoading = false;
          for (let field of Object.keys(response.data.error)) {
            this.makeToastVariant(
              "danger",
              response.data.error[field][0],
              "Error"
            );
          }
        });
    },
    resetUpdateStatusModal() {
      this.statusObj.datetime = "";
      this.statusObj.status = "";
      this.statusObj.location = "";
      this.statusObj.remarks = "";
    },
    setMarkasPaid() {
      if (this.selected.length == 0) {
        this.makeToastVariant(
          "error",
          "No Shipment is Selected",
          "Error"
        );
        return;
      }
      var shipmentids = [];
      this.selected.forEach((value) => {
        shipmentids.push(value.id);
      });
      this.isLoading = true;
      ApiService.post("bulkmarkAsPaid", { shipment_ids: shipmentids })
        .then(() => {
          this.$nextTick(() => {
            this.getAllShipments();
            this.makeToastVariant(
              "success",
              "Shipments marked as paid successfully",
              "Success"
            );
            this.isLoading = false;
          });
        })
        .catch(({ response }) => {
          this.isLoading = false;
          alert("Error", response);
        });
    },
    sendInvoices() {
      if (this.selected.length == 0) {
        this.makeToastVariant(
          "error",
          "No Shipment is Selected",
          "Error"
        );
        return;
      }
      var shipmentids = [];
      this.selected.forEach((value) => {
        shipmentids.push(value.id);
      });
      this.isLoading = true;
      ApiService.post("sendBulkInvoices", { shipment_ids: shipmentids })
        .then(() => {
          this.$nextTick(() => {
            // this.getAllShipments();
            this.makeToastVariant(
              "success",
              "Invoices sent successfully",
              "Success"
            );
            this.isLoading = false;
          });
        })
        .catch(({ response }) => {
          this.isLoading = false;
          alert("Error", response);
        });
    },
    GetRecordC(id) {
      this.$router.push({
        name: "cdashboard",
        params: {
          id: id,
        },
      });
    },
    deleteShipments() {
      if (confirm("Do you really want to delete the selected shipments?")) {

        var shipment_ids = [];
        this.selected.forEach((value) => {
          shipment_ids.push(value.id);
        });
        this.isLoading = true;
        ApiService.post("bulkDeleteShipments", { shipment_ids: shipment_ids })
          .then(() => {
            this.isLoading = false;
            this.getAllShipments();
            this.selected =[];
            this.$nextTick(() => {
              this.makeToastVariant(
                "success",
                "Shipments deleted successfully",
                "Success"
              );
            });
          })
          .catch(({ response }) => {
            this.isLoading = false;
            for (let field of Object.keys(response.data.error)) {
              this.makeToastVariant(
                "danger",
                response.data.error[field][0],
                "Error"
              );
            }
          });
      }
    }
  },
};
</script>
  